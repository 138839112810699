<template>
  <div id="main" class="main__ukraine main__region" 
    :class="regionClass">
    <div class="map-zone__controls">
      <div class="container">
        <a href="#" @click.prevent="isFilter=!isFilter">Фільтри</a>
      </div>        
    </div>

    <div class="map-zone container"
      :class="{'show-filter': isFilter, 'show-mapinfo': isMapInfo}" 
    >      
      <div v-show="!loaded" class="loading">loading......</div>

      <div class="map-ukr__inner map__inner"
          @click="isFilter=false;" 
      >
        <mapRegion 
          :regSVGLoaded="(regSVGLoaded && regDataLoaded)"
          :rayList="rayList"
          :viewBox="viewBox"
          :regionList="regionList"
          :regionsData="regionsData"
          :idReg="idReg.length < 2 ? '0' + idReg : '' + idReg"
        />
      </div>

      <div class="map-zone__toolbar">
        <mapTabs />
      </div>

    </div><!-- map-zone -->

    <billboard />

    <div class="grid-zone grid-zone--region container">
      <core-grid
        :dataList="table.gridData"
        :columns="table.gridColumns"
        :filter-key="table.searchQuery"
        :url="'/roads?ray='"
        :routerLink="true"
      />

      <core-sidebar />

    </div>

  </div>
</template>

<script>
  
  import axios from 'axios';
  import mapRegion from './../../components/body/map-region.vue';
  import mapTabs from './../../components/body/map-tabs.vue';
  import billboard from './../../components/body/billboard.vue';

  const convert = require('xml-js');
  let getLoadDelay;

  export default {
    metaInfo() {
      return {
        titleTemplate: '%s - Області',
        meta: [
          // { name: 'description', content:  'Середні ціни на бензин, дизельне паливо і скраплений газ на АЗС України. Достовірна інформація про роздрібному ринку нафтопродуктів України. Найповніший охоплення брендів і марок палива.'},
          { property: 'og:title', content: "АЗС України - Ціни на АЗС в регіонах України"},
          // { property: 'og:site_name', content: 'АЗС України'},
          // {property: 'og:type', content: 'website'},    
          // {name: 'robots', content: 'index,follow'} 
        ]
      }
    },    
    components: {
      mapRegion,
      mapTabs,
      billboard
    },
    data: () => {
      return {
        loaded: false,
        regSVGLoaded: false,
        regDataLoaded: false,
        regGridLoaded: false,
        getDataXml: '/assets/data/getDataReg.xml',
        getGridDataURL: '/assets/data/getGridDataRegsJSON.pl',
        regionsData: {},
        regionList: [],
        rayList: [],
        viewBox: '',
        table: {
          searchQuery: '',
          gridColumns: [
            { 
              label: '',
              name: 'name'
            }
          ],
          gridData: []
        },
        isFilter: false,
        isMapInfo: false,
				brend: -1,
        idReg: 80,
        defaultReg: 80,
        errors: []
      }
    },
    computed: {
      regionClass() {
        let classes = this.regSVGLoaded ? 'loaded': '';
        classes += this.idReg ? ' region__' + this.idReg : ''
        return classes;
      }
    },
    watch: {
      $route () {
        const idReg = this.$route.params.idReg ? this.$route.params.idReg : 0;
        this.idReg = idReg ? idReg : this.defaultReg;
        this.trackEvent(idReg);
        this.updateAll();
      }
    },
    methods: {
      updateAll(){
        this.updateMap();
        this.updateMapData();
        this.tableUpdate();
      },
      updateMap() {
        const idReg = this.idReg;
        this.regSVGLoaded = false;
        this.regDataLoaded = false;
        this.regGridLoaded = false;
        this.loaded = false;

				if (!this.isRegSVGLoaded(idReg)) {
          this.loadRegSVG(idReg);
          
        } else {
          clearTimeout(getLoadDelay);
          const rayData = this.$store.getters.getRegionMap[this.idReg];
          if (rayData) {
            this.rayList =rayData.rays;
            this.viewBox = rayData.viewBox;
          }

          getLoadDelay = setTimeout(() => {
            this.regSVGLoaded = true;
            this.loaded = true;
          }, 500);
          
				}
      },
    
      isRegSVGLoaded(idReg) {
        const currentReg = this.$store.getters.getRegionMap[idReg];
        return currentReg ? true : false;
      },
      
      isRegDataLoaded(idReg) {
        const currentReg = this.$store.getters.getRegionsData[idReg];
        return currentReg ? true : false;
      },

      updateMapData(params) {
        this.regDataLoaded =false;
        params = params ? params : this.$store.getters.getFuelSelected;

        let paramsStr = params && params.length ? 'postData=' + params.join("|") : '';
        let url = `/assets/data/getDataRegs.xml?brend=${this.brend}&reg=${this.idReg}&postData=${paramsStr}`;

        url = `http://azs.uapetrol.com/cgi-bin/getDataRegs.pl?brend=${this.brend}&reg=${this.idReg}&${paramsStr}`;

        axios
        .get(url)
        .then(response => {
          const jsonStr = convert.xml2json(response.data, {compact: true});
          const json = JSON.parse(jsonStr);
          const regArr = json.map.item;
          const regInfo = json.map._attributes;
          const regionsData = {
            regInfo: {
              chDate: regInfo.chDate,
              stepPrice: regInfo.stepPrice,
              price1: regInfo.price1,
              price2: regInfo.price2,
              price3: regInfo.price3,
              price4: regInfo.price4
            }
          };
          for(let i = 0; i < regArr.length; i++){
            regionsData[regArr[i]._attributes.id] = regArr[i]._attributes;
          }
          this.regionsData = regionsData;
          this.regDataLoaded =true;
        })
        .catch(error => {
          console.log(error);
          this.errors.push(error)
        });
      },
      
      loadRegSVG(idReg) {
        axios
        .get(`/assets/data/regions/${idReg}.json`)
        .then(response => {

          const region = {
            idReg: idReg,          
            data: { 
              rays: response.data.rays,
              viewBox: response.data.viewBox
            }
          };
          
          this.rayList = response.data.rays;
          this.viewBox = response.data.viewBox;
          this.$store.dispatch('addRegionMap', region);
          
          this.regSVGLoaded = true;
          this.loaded = true;
        })
        .catch(error => {
          console.log(error);
          this.errors.push(error)
        });
      },
      
      tableUpdate(params) {
        //Table
        params = params ? params : this.$store.getters.getFuelSelected;

        let paramsStr = params && params.length ? 'postData=' + params.join("|") : '';
        // console.log(this.getGridDataURL + `?brend=${this.brend}&reg=${this.idReg}&${paramsStr}`);

        axios
          .get(this.getGridDataURL + `?brend=${this.brend}&reg=${this.idReg}&${paramsStr}`)
          .then(response => {
            this.table.gridColumns = [
              {
                label: 'Район',
                name: 'reg'            
              },
              {
                label: 'Ціна середн.',
                name: 'avg'            
              },
              {
                label: 'Ціна мін.',
                name: 'min'            
              },
              {
                label: 'Ціна макс.',
                name: 'max'            
              },
              {
                label: 'Кіл-ть АЗС',
                name: 'kolAZS'            
              }           
            ];
            this.table.gridData = response.data.data.items;
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
      },
      trackEvent(value) {
        console.log('region', value);
        this.$gtag.event('select-region', {
          'event_category': `Region-${value}`,
          'event_label': `Region #${value} is selected`,
          'value': 1
        })
      }
    },
    mounted() {
      this.idReg = this.$route.params.idReg ? this.$route.params.idReg : this.defaultReg;
      this.updateMap();
      this.updateMapData();
      this.tableUpdate();
      
    },
    created(){
      // //FuelsList
      // axios
      //   .get('/assets/data/fuel.json')
      //   .then(response => {
      //     const fuelList = response.data.fuels[0].groups;
      //     this.$store.dispatch('createFuelList', fuelList);
      //   })
      //   .catch(error => {
      //     console.log(error);
      //     this.errors.push(error)
      // });

      axios
        .get('/assets/data/main.json')
        .then(response => {
          this.getDataXml = response.data.getDataRegs;
          this.getGridDataURL = response.data.getGridDataRegJSON;
          this.regionList = response.data.regionList;
          
          this.updateAll();
        })
        .catch(error => {
          console.log(error);
          this.errors.push(error)
        });
    }
  }
</script>
